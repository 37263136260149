import { TaskItemDto } from '@api/Api';
import { statusMenuList } from '@components/Tasks/Status/statusConfig';
import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';
import moment from 'moment';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: false,
  headers: [
    'Workspace',
    'Campaign',
    'Name',
    'Description',
    'Asset',
    'AssetId',
    'Status',
    'Board',
    'Priority',
    'Due date',
    'Assignee'
  ]
};

export const exportAsCSV = ({
  workspaceName,
  tasksList
}: {
  workspaceName: string;
  tasksList: TaskItemDto[];
}) => {
  const date = moment(new Date()).format('DD.MM.YYYY');
  const csvExporter = new ExportToCsv();
  csvExporter.options = {
    ...csvOptions,
    filename: `tasks_${date}`
  };
  const data = tasksList.map((x: TaskItemDto) => ({
    workspace: workspaceName,
    campaign: x?.campaign?.name ?? '',
    name: x.name,
    description: x.description ?? '',
    asset: x.asset
      ? `${x.asset.selectedVersion.name}.${x.asset.selectedVersion.extension}`
      : '',
    assetId: x.asset?.id ?? '',
    status:
      statusMenuList.find(({ status }) => status === x.status)?.name ?? '',
    board: x.board.name,
    priority: x.priority ?? '',
    dueDate: x.dueDate
      ? moment(x.dueDate).format(
          moment(x.dueDate).minutes() === 59 ? 'MMM DD' : 'MMM DD, h:mma'
        )
      : '',
    assignees: x.assignees
      .map((x) =>
        x.user ? `${x.user.name} - ${x.user.email}` : x.invitationEmail
      )
      .join(', ')
  }));
  csvExporter.generateCsv(data);
};

export const exportAsXML = ({
  workspaceName,
  tasksList
}: {
  workspaceName: string;
  tasksList: TaskItemDto[];
}) => {
  let xml = '<?xml version="1.0" encoding="utf-8"?>';
  const tasks = tasksList.map((task) => {
    const assignees =
      Array.isArray(task.assignees) && task?.assignees.length !== 0
        ? task.assignees.map(
            (assigner) =>
              `<user email="${assigner?.user?.email}" name="${
                assigner?.user?.name
              }" picture="${assigner?.user?.picture?.url?.split('?')?.[0]}"/>`
          )
        : ' ';
    const watchers =
      Array.isArray(task.watchers) && task?.watchers.length !== 0
        ? task.watchers.map(
            ({ user }) => `
            <watcher email="${user?.email}" name="${user?.name}"/>`
          )
        : ' ';
    const customFields =
      Array.isArray(task.customFields) && task?.customFields.length !== 0
        ? task.customFields.map(
            (field) =>
              `<field id="${field?.id}" type="${field?.type}" name="${field.name}" value="${field.value}" />`
          )
        : ' ';
    const attachments =
      Array.isArray(task.attachments) && task?.attachments.length !== 0
        ? task.attachments.map(
            (attachment) =>
              `<attachment id="${attachment?.id}" type="${attachment?.mimeType}" name="${attachment.name}" size="${attachment.size}" url="${attachment.url}" />`
          )
        : ' ';
    return `
      <task>
        <taskInfo
          id="${task.id}"
          createdAt="${task.createdAt}"
          updatedAt="${task.lastEventTime}"
          dueDate="${task.dueDate}"
          name="${task?.name}"
          description="${task?.description?.replace(/<[^>]*>?/gm, '')}"
          status="${task?.status}"
          commentsCount="${task?.commentsCount}"
          archived="${task?.archived}"
        />
        <workspace id="${task.workspaceId}" name="${workspaceName}" />
        <campaign id="${task?.campaign?.id}" name="${task?.campaign?.name}" />
        <board id="${task?.board?.id}" name="${task?.board?.name}" />
        <asset
          id="${task?.asset?.id || ''}"
          isDeleted="${task?.asset?.isDeleted || ''}"
          selectedVersionId="${task?.asset?.selectedVersion?.id || ''}"
          selectedVersionName="${task?.asset?.selectedVersion?.name || ''}"
          selectedVersionExtension="${
            task?.asset?.selectedVersion?.extension || ''
          }"
          lastVersionId="${task?.asset?.lastVersion?.id || ''}"
          lastVersionName="${task?.asset?.lastVersion?.name || ''}"
          lastVersionExtension="${task?.asset?.lastVersion?.extension || ''}"
        >
          ${
            Array.isArray(task?.asset?.path) && task?.asset?.path.length
              ? task?.asset?.path.map(
                  (p) => `<path id="${p.id}" name="${p.name}" />`
                )
              : ''
          }
        </asset>
        <watchers>${watchers}</watchers>
        <assignees>${assignees}</assignees>
        <customFields>${customFields}</customFields>
        <attachments>${attachments}</attachments>
      </task>`;
  });
  xml += `<cover>
    <tasks count="${tasksList.length}">
      ${tasks.join('')}
    </tasks>
  </cover>`;
  const date = moment(new Date()).format('DD.MM.YYYY');
  const blob = new Blob([xml], { type: 'application/xml;charset=utf-8,' });
  saveAs(blob, `tasks_${date}.xml`);
};

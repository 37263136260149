import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  Button,
  CollapsePanelProps,
  Modal,
  Row,
  Col,
  message,
  Tooltip
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { TaskItemDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import AccordionTopPanel from '@pages/MediaViewer/components/AccordionTopPanel/AccordionTopPanel';
import AccordionFilter from '@pages/MediaViewer/components/AccordionFilter';
import NoResults from '@pages/MediaViewer/components/NoResults';
import TaskItem from '@pages/MediaViewer/Tasks/components/TaskItem';
import { exportAsCSV, exportAsXML } from '@helpers/exportTasks';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

import './Tasks.less';
import {
  loadMoreAssetVersionTasks,
  openNewTaskModal,
  setAssetVersionListNeedRefresh,
  setAssetVersionListParams
} from '@redux/reducers/tasks';
import { nonNull } from '@helpers/non-null';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useFetch } from '@hooks/useFetch';
import { assetVersionStateSelector } from '@redux/selectors/tasks';
import {
  fetchTaskListInAssetVerion,
  fetchTasksCountInAssetVerion
} from '@redux/actions/tasks';
import { useCurrentWorkspace } from '@hooks/workspace';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import TaskListSkeleton from '@components/Tasks/TaskListSkeleton';
import { TasksInAssetListType, TasksOrderBy } from '@redux/types/tasks';
import { useDeleteTask } from '@hooks/tasks';
import store from '@redux/store';

type ITasksProps = Omit<CollapsePanelProps, 'header'> & {
  setSelectedCollapse(value: string): void;
  canCreateTask: boolean;
};

const sortMenu: ItemType[] = [
  { label: 'Date created', key: 'createdAt:DESC' },
  {
    label: 'Date modified',
    key: 'lastEventTime:DESC'
  }
];

const tasksMenu = [
  { label: 'All tasks', key: 'all' },
  { label: 'Assigned to me', key: 'assigned' },
  { label: 'Completed tasks', key: 'completed' },
  { label: 'In Progress', key: 'in-progress' }
];

function Tasks({
  setSelectedCollapse,
  canCreateTask,
  ...collapsePanelProps
}: ITasksProps) {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { removeComment } = useOutletContext<any>();
  const [currentWorkspace] = useCurrentWorkspace(false);
  const asset = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.assets)
  );
  const { workspaceId } = asset;
  const selectedVersion = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.selectedVersion)
  );
  const assetVersionId = selectedVersion.id;

  const orderBy = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).orderBy
  );
  const searchQuery = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).searchQuery
  );
  const listType = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).listType
  );
  const hideResolvedTasks = useTypedSelector(
    (state) =>
      assetVersionStateSelector(state, { assetVersionId }).hideResolvedTasks
  );
  const count = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).count
  );
  const taskIds = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).taskIds
  );
  const initiallyLoaded = useTypedSelector(
    (state) =>
      assetVersionStateSelector(state, { assetVersionId }).fetch.initiallyLoaded
  );
  const hasMore = useTypedSelector(
    (state) => assetVersionStateSelector(state, { assetVersionId }).hasMore
  );
  const showEmpty = initiallyLoaded && !taskIds.length;

  useFetch({
    key: `tasks-asset-version-count-${assetVersionId}`,
    selector: (state) =>
      assetVersionStateSelector(state, { assetVersionId }).countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTasksCountInAssetVerion({ workspaceId, assetVersionId, fetchType })
      )
  });

  const loading = useFetch({
    key: `tasks-asset-version-list-${assetVersionId}`,
    selector: (state) =>
      assetVersionStateSelector(state, { assetVersionId }).fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTaskListInAssetVerion({ workspaceId, assetVersionId, fetchType })
      )
  });

  useEffect(() => {
    dispatch(setAssetVersionListNeedRefresh({ assetVersionId }));
  }, [assetVersionId, dispatch]);

  const onLoadMore = useCallback(() => {
    dispatch(loadMoreAssetVersionTasks({ assetVersionId }));
  }, [assetVersionId, dispatch]);

  const commentsList = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.commentsList
  );

  const [isLoadingDeleteComment, setIsLoadingDeleteComment] =
    useState<boolean>(false);
  const [selectedDeleteCommentId, setSelectedDeleteCommentId] = useState<
    string | null
  >();

  const deleteTask = useDeleteTask();
  const onRemoveTask = useCallback(
    async (task: TaskItemDto) => {
      try {
        await deleteTask({ id: task.id });
        const commentId = task?.assetVersionComment?.id;
        if (commentId) {
          const comment = commentsList.find((item) => item?.id === commentId);
          if (comment && comment?.user?.me)
            setSelectedDeleteCommentId(commentId);
        }
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.message || 'Something went wrong',
          className: 'message-dark-modal'
        });
      }
    },
    [commentsList, deleteTask]
  );

  const onRemoveCommentById = async () => {
    if (selectedDeleteCommentId) {
      try {
        setIsLoadingDeleteComment(true);
        await removeComment(selectedDeleteCommentId);
        setSelectedDeleteCommentId(null);
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.message || 'Something went wrong',
          className: 'message-dark-modal'
        });
      } finally {
        setIsLoadingDeleteComment(false);
      }
    }
  };

  const createTask = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (!asset || !selectedVersion) return;
    dispatch(
      openNewTaskModal({
        organizationId: asset.organizationId,
        workspaceId: asset.workspaceId,
        campaign: {
          id: asset.campaignId,
          name: asset.campaignName
        },
        asset: {
          id: asset.id,
          isDeleted: !!asset.deletedAt,
          path: asset.path,
          lastVersion: nonNull(asset.asset).versions[0],
          selectedVersion
        },
        descriptionPlaceholder:
          'Describe your task so your teammates know what to do.'
      })
    );
  };

  const assetVersionIdRef = useRef(assetVersionId);
  assetVersionIdRef.current = assetVersionId;

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const hasMoreRef = useRef(hasMore);
  hasMoreRef.current = hasMore;

  const taskIdsRef = useRef(taskIds);
  taskIdsRef.current = taskIds;

  const onExport = async (value: string) => {
    while (hasMoreRef.current) {
      await new Promise<void>((resolve) => {
        requestAnimationFrame(() => requestAnimationFrame(() => resolve()));
      });
      if (assetVersionIdRef.current !== assetVersionId) return;
      if (!loadingRef.current) {
        dispatch(loadMoreAssetVersionTasks({ assetVersionId }));
      }
    }
    const taskIds = taskIdsRef.current;
    if (!taskIds.length) return;
    const state = store.getState().tasks;
    const tasks = taskIds.map((id) => nonNull(state.items[id]?.entity));
    if (value === 'XML') {
      exportAsXML({
        workspaceName: currentWorkspace?.name ?? workspaceId,
        tasksList: tasks
      });
    }
    if (value === 'CSV') {
      exportAsCSV({
        workspaceName: currentWorkspace?.name ?? workspaceId,
        tasksList: tasks
      });
    }
  };

  const setSelectedCollapseRef = useRef(setSelectedCollapse);
  setSelectedCollapseRef.current = setSelectedCollapse;
  const onOpenComment = useCallback(
    (commentId: string) => {
      navigate({
        search: `commentId=${commentId}`
      });
      setSelectedCollapseRef.current('comments');
    },
    [navigate]
  );

  const dropdownMenuItems: ItemType[] = [];

  if (count > 0) {
    dropdownMenuItems.push({
      label: 'Export tasks as',
      key: 'submenu',
      popupClassName: 'media_viewer_submenu media_viewer_mobile-overlay',
      popupOffset: [12, 0],
      onTitleClick: ({ domEvent }) => domEvent.stopPropagation(),
      children: [
        { label: 'CSV', key: 'CSV' },
        { label: 'XML', key: 'XML' }
      ]
    });
  }
  if (listType !== 'completed' && listType !== 'in-progress') {
    dropdownMenuItems.push({
      key: 'resolved-tasks',
      label: hideResolvedTasks ? 'Show resolved tasks' : 'Hide resolved tasks',
      onClick: () =>
        dispatch(
          setAssetVersionListParams({
            assetVersionId,
            hideResolvedTasks: !hideResolvedTasks
          })
        )
    });
  }

  return (
    <AccordionTopPanel
      name="Tasks"
      dropdownMenu={dropdownMenuItems.length ? dropdownMenuItems : undefined}
      onDropdownHandler={onExport}
      count={count || 0}
      extraction={
        canCreateTask ? (
          <Tooltip
            title="Add new task"
            overlayClassName="media_viewer_tooltip"
            placement="leftBottom"
          >
            <Button
              className="media_viewer_btn b-btn-plus b-only-icon"
              onClick={createTask}
            >
              <PlusSvg className="b-white-icon" />
            </Button>
          </Tooltip>
        ) : null
      }
      {...collapsePanelProps}
    >
      <>
        <AccordionFilter
          orderBy={orderBy}
          sortMenu={sortMenu}
          onDropdownHandler={(listType) => {
            dispatch(
              setAssetVersionListParams({
                assetVersionId,
                listType: listType as TasksInAssetListType
              })
            );
          }}
          onSortHandler={(orderBy) =>
            dispatch(
              setAssetVersionListParams({
                assetVersionId,
                orderBy: orderBy as TasksOrderBy
              })
            )
          }
          searchQuery={searchQuery}
          onSearchOpenChange={(open) => {
            dispatch(
              setAssetVersionListParams({
                assetVersionId,
                searchQuery: open ? '' : null
              })
            );
          }}
          onSearchHandler={(searchQuery) => {
            dispatch(
              setAssetVersionListParams({
                assetVersionId,
                searchQuery
              })
            );
          }}
          dropdownMenu={tasksMenu}
          dropdownMenuText={tasksMenu.find((x) => x?.key === listType)?.label}
          exportMenu={dropdownMenuItems.length ? dropdownMenuItems : undefined}
          onExportHandler={onExport}
        />
        <div className="media_viewer_inner b-tasks-container">
          {!showEmpty && (
            <InfiniteScroll
              loading={loading}
              hasMore={hasMore}
              loader={
                <TaskListSkeleton
                  count={count - taskIds.length}
                  border={false}
                  height={90}
                />
              }
              alwaysShowLoader
              next={onLoadMore}
            >
              {taskIds.map((id) => (
                <TaskItem
                  key={id}
                  taskId={id}
                  onOpenComment={onOpenComment}
                  onRemoveTask={onRemoveTask}
                />
              ))}
            </InfiniteScroll>
          )}
          {showEmpty && <NoResults />}
        </div>
        <Modal
          open={!!selectedDeleteCommentId}
          footer={null}
          centered={true}
          closeIcon={<CrossSvg />}
          width={468}
          className="media_viewer_task-modal"
          onCancel={() => setSelectedDeleteCommentId(null)}
        >
          <div className="modal_container">
            <h2 className="modal_container__title">Delete comment?</h2>
            <div className="modal_container__subtitle">
              There is a comment attached to this task. Do you want to also
              delete the comment?
            </div>
            <Row justify="end" gutter={20}>
              <Col>
                <Button
                  type="text"
                  disabled={isLoadingDeleteComment}
                  onClick={() => setSelectedDeleteCommentId(null)}
                >
                  No
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={isLoadingDeleteComment}
                  onClick={onRemoveCommentById}
                >
                  Yes, delete comment
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    </AccordionTopPanel>
  );
}

export default Tasks;

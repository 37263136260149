import React from 'react';
import { ReactComponent as SearchSvg } from '@assets/icons/search-magic.svg';

import './NoResults.less';

function NoResults() {
  return (
    <div className="media_viewer_no-results">
      <div className="media_viewer_no-results-container">
        <div className="media_viewer_no-results-icon">
          <SearchSvg />
        </div>
        <div className="media_viewer_no-results-title">No results found</div>
        <div className="media_viewer_no-results-text">
          It seems we can’t find any results based on your search
        </div>
      </div>
    </div>
  );
}

export default NoResults;
